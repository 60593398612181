import React from "react";
import "./Offerings.scss";
import digitising from "../../../Assets/Images/home/Digitising.png";
import oneClick from "../../../Assets/Images/home/oneClick.png";
import mob from "../../../Assets/Images/home/mob.gif";

const Offerings = () => {
  const features = [
    {
      title: "Minimal Documentation",
      description: "Your Store Online in a few clicks.",
      image: oneClick,
    },
    {
      title: "10x Growth",
      description: "Minimal Documention",
      image: digitising,
    },
    {
      title: "Analytics",
      description:
        "Get started with the most advanced  Order Management System.",
      image: mob,
    },
  ];
  return (
    <div className="home-v3-offerings">
      <p className="home-v3-offerings-heading">Why Should You Use Us?</p>
      {/* <p style={{ textAlign: 'center' }}>It is your director of operations.</p> */}
      <div className="offerings-cards-containers">
        {features?.map((feature, index) => (
          <div className="feature-card" key={index}>
            <p className="feature-title">{feature.title}</p>
            <img src={feature.image} alt="feature" height={300} width={300} />
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Offerings;

