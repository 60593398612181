import React from 'react';
import "./ContactUs.scss";
import bgImg from "../../../Assets/Images/V3/talk-to-us.png";

const ContactUs = () => {
    return (
        <div className='home-v3-contact-us'>
            <img src= {bgImg} alt='' width={1200} />
            <div className='home-v3-contact-us-content'>
                <p className='home-v3-contact-us-heading'>Talk to us</p>
                <p style={{fontSize:"1.2em"}}>Your partner may avoid you, but we won't</p>
                <button className='contact-us-btn'>Care@Aiktech.In</button>
            </div>
        </div>
    )
}

export default ContactUs