import { call, put, takeLatest, select } from "redux-saga/effects";
import { paymentAPI, tenantAPI } from "../../Api";
import { actionsCreator } from "../actions/actionsCreator";
import {
  FETCH_SUBSCRIPTIONS,
  REGISTER_TENANT_USER,
  REGISTER_TENANT,
  VERIFY_TENANT_OTP,
  FETCH_TENANT_CATEGORIES,
  SEND_TENANT_USER_OTP,
  HANDLE_PAYMENT_SUCCESS,
  HANDLE_PAYMENT_FAILURE,
  INITIATE_PAYMENT,
  FETCH_ALL_TENANTS,
  SEARCH_BRANDS,
  FETCH_TENANT_DETAILS
} from "../actions/actionTypes";
import get from "lodash/get";
import { toast } from "react-toastify";
import {
  errorMsg,
  extractTenantRegistrationErrors,
  extractuserRegistrationError,
  storeToken,
} from "../../Utils";
import { isEmpty } from "lodash";
import { storePhoneNumber } from "../../Utils/general-utils";

export const getSelectedTenant = (state) =>
  get(state, "tenantData.selectedTenant", {});

function* getSubscriptions(action) {
  try {
    const response = yield call(tenantAPI.getSubscriptions);
    const data = get(response, "data.data", {});
    yield put(actionsCreator.SET_SUBSCRIPTIONS({ list: data }));
  } catch (error) {
    yield put(actionsCreator.SET_SUBSCRIPTIONS({ list: [] }));
  }
}

function* getTenantCategories(action) {
  try {
    const response = yield call(tenantAPI.getTenantCategories);
    const data = get(response, "data", {});
    yield put(actionsCreator.SET_TENANT_CATEGORIES({ list: data }));
  } catch (error) {
    yield put(actionsCreator.SET_TENANT_CATEGORIES({ list: [] }));
  }
}

function* registerTenantUser(action) {
  try {
    const response = yield call(tenantAPI.registerTenantUser, action.payload);
    const data = get(response, "data", {});
    yield put(actionsCreator.SEND_TENANT_USER_OTP(action.payload));
    toast.success("User is registered successfully");
    // yield put(actionsCreator.SET_SHOW_PREVIEW(true));
  } catch (error) {
    const errorObj = extractTenantRegistrationErrors(error);
    yield put(actionsCreator.SET_TENANT_ERRORS(errorObj));
  }
}

function* sendTenantUserOtp(action) {
  try {
    const response = yield call(tenantAPI.sendTenantUserOtp, action.payload);
    const data = get(response, "data.data", {});
    const errorMsg = extractuserRegistrationError(response);
    if (errorMsg) {
      toast.error(errorMsg);
    } else {
      const sessionId = get(data, "OTP", "");
      yield put(actionsCreator.SET_TENANT_FORM_DATA({ sessionId: sessionId }));
      toast.success("Please verify the OTP");
    }
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
    yield put(actionsCreator.SET_TENANT_FORM_DATA({ sessionId: "" }));
  }
}

function* verifyTenantOtp(action) {
  try {
    const response = yield call(tenantAPI.verifyTenantOtp, action.payload);
    const data = get(response, "data", {});
    toast.success("OTP is verified successfully");
    const user = get(data, "user", "");
    yield put(actionsCreator.SET_USER_DETAILS(user));
    const token = get(data, "token.access", "");
    const permissons = get(user, 'user.permissions')
    if (token) {
      storeToken(token);
      yield put(
        actionsCreator.SET_LOGIN({
          isLoggedIn: true,
        })
      );
    }
    if (user){
      storePhoneNumber(user?.phone_number)

    }
    
    yield put(actionsCreator.SET_CHANGE_SLIDE(true));
  } catch (error) {
    yield put(actionsCreator.SET_USER_DETAILS({}));
    const msg = extractuserRegistrationError(error.response) || errorMsg(error);
    toast.error(msg);
  }
}

function* createTenant(action) {
  try { 
    const response = yield call(tenantAPI.createTenant, action.payload);
    const data = get(response, "data.data", {});
    yield put(actionsCreator.SET_TENANT({ selectedTenant: response.data }));
    yield put(actionsCreator.SET_SHOW_PREVIEW(true));
    toast.success(`${action?.payload?.description} registered successfully`);
  } catch (error) {
    const status = error["response"].status;
    if(status===400){
      toast.error(error['response'].data?.message || "Sub-domain already exists");
    } 

    yield put(actionsCreator.SET_TENANT({ selectedTenant: {} }));
    return status;
  }
}

function* fetchAllTenants() {
  try {
    const response = yield call(tenantAPI.getAllTenants);
    const data = get(response, "data.data", {});
    const selectedTenant = yield select(getSelectedTenant);
    yield put(actionsCreator.SET_TENANT({ allTenants: data }));
    if (isEmpty(selectedTenant) && data && data.length > 0) {
      const tenant = get(data, "0", {});
      yield put(actionsCreator.SET_TENANT({ selectedTenant: tenant }));
    }
  } catch (error) {}
}

function* initiatePayment(action) {
  try {
    const payload = get(action, "payload", {});
    const callback = get(action, "payload.callback", {});
    delete payload.callback;
    const response = yield call(paymentAPI.initiatePayment, payload);
    const data = get(response, "data.data", {});
    callback(data);
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* handlePaymentSuccess(action) {
  try {
    const response = yield call(paymentAPI.paymentSuccess, action.payload);
    const data = get(response, "data.data", {});
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* handlePaymentFailure(action) {
  try {
    const response = yield call(paymentAPI.paymentFailure, action.payload);
    const data = get(response, "data.data", {});
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* searchBrands(action) {
  try {
    yield put(actionsCreator.SET_BRANDS({ loading: true }));
    const response = yield call(tenantAPI.searchBrands, action.payload);
    const data = get(response, "data.results", {});
    yield put(actionsCreator.SET_BRANDS({ list: data, loading: false }));
  } catch (error) {
    yield put(actionsCreator.SET_BRANDS({ list: [], loading: false }));
  }
}

function* fetchTenantDetails(action) {
  try {
    const response = yield call(tenantAPI.fetchCompanyDetails, action.payload);
    const data = get(response, "data.results", {});
    yield put(actionsCreator.SET_BRANDS({ list: data, loading: false }));
  } catch (error) {
    yield put(actionsCreator.SET_BRANDS({ list: [], loading: false }));
  }
}

export default function* tenantSagas() {
  yield takeLatest(FETCH_SUBSCRIPTIONS, getSubscriptions);
  yield takeLatest(REGISTER_TENANT_USER, registerTenantUser);
  yield takeLatest(SEND_TENANT_USER_OTP, sendTenantUserOtp);
  yield takeLatest(REGISTER_TENANT, createTenant);
  yield takeLatest(VERIFY_TENANT_OTP, verifyTenantOtp);
  yield takeLatest(FETCH_TENANT_CATEGORIES, getTenantCategories);
  yield takeLatest(FETCH_ALL_TENANTS, fetchAllTenants);
  yield takeLatest(INITIATE_PAYMENT, initiatePayment);
  yield takeLatest(HANDLE_PAYMENT_SUCCESS, handlePaymentSuccess);
  yield takeLatest(HANDLE_PAYMENT_FAILURE, handlePaymentFailure);
  yield takeLatest(SEARCH_BRANDS, searchBrands);
  yield takeLatest(FETCH_TENANT_DETAILS, fetchTenantDetails);
}
