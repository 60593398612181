import React, { useState } from 'react';
import './AddStock.scss';
import { toast } from 'react-toastify';
import { add_hsnCode } from '../../../Api/cmsAPI';

const AddHsnCodeModal = ({setOpenAddHsnCodeModal}) => {
    const [code, setCode] = useState('');
    const [description, setDescription] = useState('');
    const [gst, setGst] = useState('');
    const [isActive, setIsActive] = useState(false);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if(gst >= 100){
                return toast.error("GST should be less than 100")
            }
            const payload = {
                code : code,
                description : description,
                gst : gst,
                active : isActive,
            }
            await add_hsnCode(payload);
            toast.success("New HSN Code Added Successfully")
            setOpenAddHsnCodeModal(false);
        } catch (err) {
            console.log(err);
            toast.error("Error while adding new HSN Code");
        }
    };

    return (
        <>
            <h3 style={{ textAlign: 'center', marginBottom: "10px" }}>Add New HSN Code</h3>
            <form onSubmit={handleSubmit} className="form-container">
                <div className="form-group">
                    <label>Code:</label>
                    <input
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        className="form-input"
                    />
                </div>

                <div className="form-group">
                    <label>Description:</label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="form-input"
                        style={{ height: "60px" }}
                    />
                </div>

                <div className="form-group">
                    <label>GST:</label>
                    <input
                        type="text"
                        value={gst}
                        onChange={(e) => setGst(e.target.value)}
                        className="form-input"
                    />
                </div>

                <div className="form-group" style={{ width: '140px', }}>
                    <label style={{marginTop:'5px'}}>Active:</label>
                    <input
                        type="checkbox"
                        checked={isActive}
                        onChange={(e) => setIsActive(e.target.checked)}
                        className="form-checkbox"
                        style={{ width: "fitContent", cursor: "pointer" }}
                        required
                    />
                    <span>{isActive ? 'Yes' : 'No'}</span>
                </div>
                <div style={{display:'flex' , justifyContent:'center'}}>
                    <button type="submit" className="form-button">
                        Add
                    </button>
                </div>
            </form>
        </>
    );
};

export default AddHsnCodeModal;
