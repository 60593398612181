import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./SingleSlider.scss";
import starIcon from "../../Assets/Images/home/rating-star.png";
import ProfileIcon from "../../Assets/Images/V3/profile-icon.png";

const SingleSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  // Auto-slide every 10 seconds
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     goToNext();
  //   }, 10000); // Change slide every 5 seconds

  //   return () => {
  //     clearInterval(interval); // Cleanup the interval on unmount
  //   };
  // }, [currentIndex, slides]);

  // Listen for left and right keyboard arrow keys
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowRight") {
        goToNext();
      } else if (e.key === "ArrowLeft") {
        goToPrevious();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex]);

  const currentSlide = slides[currentIndex] ? slides[currentIndex] : {};
  const { rating = "", msg = "", profileIcon } = currentSlide || {};
  const stars = [...Array(Number(rating))];

  return (
    <div className="SingleSlider">
      <motion.div
        key={currentIndex}
        className="SingleSlider__Content"
        initial={{ opacity: 0, scale: 0.7, rotateY: -15 }}
        animate={{ opacity: 1, scale: 1, rotateY: 0 }}
        exit={{ opacity: 0, scale: 0.9, rotateY: 15 }}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        <div className="main-section">
          <div>
            <img src={profileIcon} alt="" className="profile-icon" />
            <div className="SingleSlider__Content__RatingIcon">
              {stars && stars.length > 0
                ? stars.map((_, index) => (
                    <img key={index} src={starIcon} alt="star-icon" />
                  ))
                : null}
            </div>
          </div>
          <div className="SingleSlider__Content__Description">{msg}</div>
        </div>
        {/* Dot Indicator */}
        <div className="SingleSlider__DotContainer">
          {slides.map((_, index) => (
            <div
              key={index}
              className={`SingleSlider__Dot ${currentIndex === index ? "active" : ""}`}
              onClick={() => setCurrentIndex(index)} // Jump to specific slide
            />
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default SingleSlider;
