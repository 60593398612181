import React from 'react'
import "./Home.scss";
import Hero from './Hero';
import SetupSteps from './SetupSteps';
import Offerings from './Offerings';
import Testimonials from './Testimonials';
import ContactUs from './ContactUs';

const Home = () => {
    return (
        <div className='home-v3'>
            <Hero />
            <SetupSteps />
            <Offerings />
            <Testimonials />
            <ContactUs />
        </div>
    )
}

export default Home