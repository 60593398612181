import get from "lodash/get";
import { useSelector } from "react-redux";
import { debounce } from "./debounce-utils";


export const storeToken = (token) => {
  localStorage.setItem("auth_token", token);
};

export const getToken = () => {
  let token = localStorage.getItem("auth_token");
  return token;
};
export const getPhoneNumber=(props)=>{
  let phone_number = localStorage.getItem("phone_number");
  return phone_number

}
export const storePhoneNumber=(props)=>{
  let temp = localStorage.setItem('phone_number', props)
}

//Function to CheckPermission for the components
//Note-->Not to use Inside condiotional Rendering
 export const CheckPermissionFunc = (prop) => {
  const mapStateToProps = ({ permissionProduct = {} }) => ({
    permissionProduct,
  });
  const {
    permissionProduct: { list: PermissionList = [] },
  } = useSelector(mapStateToProps);
  function extractArrays(obj) {
    let result = []
    for (let key in obj) {
        if (Array.isArray(obj[key])) {
            result = result.concat(obj[key])
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            result = result.concat(extractArrays(obj[key])); 
        }
    }

    return result
}
const mergedArray = extractArrays(PermissionList)
    .filter((value, index, self) =>        
        index === self.findIndex((item) => item.name === value.name)
    )

  if(PermissionList?.role==='ADMIN'){
    return true
  }
  if (Array.isArray(mergedArray) ) {
    return mergedArray.some(item => item.name === prop);
  }
  return false 
};

export const errorMsg = (error) => {
 
  if (error && error["response"]) {
    const msg =
      get(error, "response.data.msg", "") ||
      get(error, "response.data.message", "");
    return msg;
  }
  return "Something went wrong!";
};

export const formatOptions = (options = [], key, val, act) => {
  let formattedOptions = [];
  formattedOptions = options.map((element) => {
    let name = get(element, key, "");
    let value = get(element, val, "");
    let active = get(element, act, "");
    return {
      label: name,
      value: value,
      active: active,
    };
  });
  return formattedOptions;
};

export function isNewLine(str, char = "\n") {
  return str.indexOf(char) > -1;
}
export const removeNewLine = (str) => {
  return str.replace("\n", "");
};

export const findLabel = (options = [], value) => {
  let option = options.find((element) => {
    return element.value === value;
  });
  return option ? option.label : "";
};

export const formatName = (name) => {
  let formattedName = "";
  if (name) {
    let nameString = name.split(" ");
    if (nameString && nameString.length > 0) {
      formattedName = nameString[0];
    }
  }
  return formattedName ? formattedName : "User";
};

export const formatOptionsData = (options, nameKey, valueKey) => {
  const formattedOptions = options.map((element) => {
    let name = get(element, nameKey, "");
    let value = get(element, valueKey, "");
    return {
      label: name,
      value: value,
      data: element,
    };
  });
  return formattedOptions;
};

export const validateFormData = (errors) => {
  const isInvalid = Object.keys(errors).some((key) => {
    return (
      errors[key] && errors[key] && errors[key]["error"] && errors[key]["msg"]
    );
  });
  return isInvalid ? isInvalid : false;
};


const delay = (milliseconds) => {
  return new Promise(resolve => {
      setTimeout(() => {
          resolve('Promise resolved after ' + milliseconds + 'ms');
      }, milliseconds);
  });
};



export const fetchData = ( data = [] , page ) => {
  const size = 18;
  const findex = page * size;
  const lindex = findex + size;

  let item;
  if(lindex > data.length){
    item = data?.slice(findex);
  }else{
    item = data?.slice(findex, lindex);
  }


  return item;
}