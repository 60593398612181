import React, { useEffect, useRef } from "react";
import "./SetupSteps.scss";
import Step1 from "../../../Assets/Images/V3/setup-steps/step1.png";
import Step2 from "../../../Assets/Images/V3/setup-steps/step2.png";
import Step3 from "../../../Assets/Images/V3/setup-steps/step3.png";

const SetupSteps = () => {
  const steps = [
    {
      title: "Signup",
      description: "Sign Up with your email address Or mobile number.",
      image: Step1,
    },
    {
      title: "Business Details",
      description: "Add your Business details - Name, Logo, Address.",
      image: Step2,
    },
    {
      title: "Plan Selection",
      description:
        "Select your plan. Upload your product inventory in an excel sheet and you are ready to sell through your app and website.",
      image: Step3,
    },
  ];

  const stepRefs = useRef(steps.map(() => React.createRef()));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    stepRefs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      stepRefs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  return (
    <div className="home-v3-setup-steps">
      <p className="home-v3-setup-steps-heading">
        Your ideas to reality in 3 easy steps
      </p>
      <div className="steps-container">
        {steps.map((step, index) => (
          <div
            key={index}
            ref={stepRefs.current[index]}
            className="step"
            style={{ flexDirection: index % 2 !== 0 ? "row-reverse" : "row" }}
          >
            <img src={step.image} alt="" />
            <div className="step-content">
              <span className="step-heading">{`${index + 1}. ${step.title}`}</span>
              <p>{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SetupSteps;