import React from "react";
import { useSelector } from "react-redux";
import { Banner, WhyShop } from "../../Components";
import "./Home.scss";

const mapStateToProps = ({ auth }) => ({
  auth,
});

const Home = () => {
  const { auth } = useSelector(mapStateToProps);
  const { isLoggedIn } = auth;
  return (
    <div>
      <div style={{ position: "relative" }}>
        <Banner showButton={isLoggedIn} />
        <WhyShop />
      </div>
    </div>
  );
};

export default Home;
