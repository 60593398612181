import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import './Builder.scss';
import { Suspense } from 'react';
import { Modal } from '../../Components';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from "../../Assets/Images/Template/builderloader.gif";
const componentTypeToPathMap = new Map([
    ["header", () => import("../../Templates/home-template/Header/Header")],
    ["search-bar", () => import('../../Templates/home-template/SearchBar')],
    ["banner", () => import("../../Templates/home-template/Banner")],
    ["promotional-bar", () => import("../../Templates/home-template/PromotionalBar")],
    ["featured-products", () => import("../../Templates/home-template/Featured")],
    ["category-showcase", () => import("../../Templates/home-template/Categories")],
    ["offer-grid", () => import("../../Templates/home-template/Offers")],
    ["testimonials", () => import("../../Templates/home-template/Testimonials")],
    ["video-section", () => import("../../Templates/home-template/VideoSection")],
    ["newsletter-signup", () => import("../../Templates/home-template/NewsLetter")],
    ["recently-viewed", () => import("../../Templates/home-template/NewsLetter")],
    ["brand-showcase", () => import("../../Templates/home-template/BrandShowcase")],
    ["blog-highlights", () => import("../../Templates/home-template/Blogs")],
    ["footer", () => import("../../Templates/home-template/Footer")],
    [ "company-showcase",
        ()=> import("../../Templates/home-template/Companyshowcase/Companyshowcase")
      ],
]);

const getLoadingText = (type) => {
    const loadingTexts = {
        "header": "Header",
        "search-bar": "Search Bar",
        "banner": "Banner",
        "promotional-bar": "Promotional Bar",
        "featured-products": "Featured Products",
        "category-showcase": "Category Showcase",
        "offer-grid": "Offers",
        "testimonials": "Testimonials",
        "video-section": "Video Section",
        "newsletter-signup": "Newsletter Signup",
        "recently-viewed": "Recently Viewed",
        "brand-showcase": "Brand Showcase",
        "blog-highlights": "Blog Highlights",
        "footer": "Footer",
    };
    return loadingTexts[type] || "Component";
};

const DynamicComponentLoader = React.forwardRef(({ type, delay, onLoad, onAnimationComplete, ...props }, ref) => {
    console.log(type)
    const loadComponent = componentTypeToPathMap.get(type);

    useEffect(() => {
        if (onLoad && loadComponent) {
            onLoad(); // Call the onLoad callback when the component is ready
        }
    }, [loadComponent, onLoad]);

    if (!loadComponent) {
        return null;
    }

    const Component = React.lazy(loadComponent);
    const loadingText = getLoadingText(type);

    return (
        <motion.div
            className="dynamic-component-container"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: delay }} // Reduce delay for faster animation
            onAnimationComplete={onAnimationComplete}
            ref={ref}
            tabIndex={-1}
        >
            <Suspense fallback={<div className="loading-text">
                {`Loading ${loadingText}...`}
            </div>}>
                <Component {...props} />
            </Suspense>
        </motion.div>
    );
});

const Builder = () => {
    
    const [template, setTemplate] = useState(null);
    const { selectedTenant: tenantDetails } = useSelector((state) => state.tenantData);

    const fetchConfig = () => {
        try {
            const config = tenantDetails?.template_details;
            setTemplate(config);
        } catch (err) {
            console.error(err);
            toast.error("Error while creating template");
        }
    };

    useEffect(() => {
        if (tenantDetails) {
            fetchConfig();
        }
    }, [tenantDetails]);

    const [renderedComponents, setRenderedComponents] = useState([]);
    const [currentRendering, setCurrentRendering] = useState("");
    const [isRendering, setIsRendering] = useState(false);
    const [allComponentsRendered, setAllComponentsRendered] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const componentRefs = useRef([]);

    const focusNextComponent = (index) => {
        const nextComponent = componentRefs.current[index + 1];
        if (nextComponent) {
            const offsetTop = nextComponent.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({
                top: offsetTop,
                behavior: 'smooth',
            });
        }
    };

    const renderComponentsSequentially = async () => {
        if (!template || !template.homepageConfig) return;

        const sections = template.homepageConfig.sections;

        for (let componentIndex = 0; componentIndex < sections.length; componentIndex++) {
            const section = sections[componentIndex];
            const loadingText = getLoadingText(section?.type);

            setIsRendering(true); // Show modal while rendering
            setCurrentRendering(loadingText); // Update current rendering component

            // Add the section to rendered components
            setRenderedComponents((prevRendered) => [
                ...prevRendered,
                section
            ]);

            // Wait for the component to load and render
            await new Promise((resolve) => {
                // Set a timeout for both focus and modal text update
                setTimeout(() => {
                    focusNextComponent(componentIndex); // Focus on the next component
                    resolve();
                }, 800);
            });
        }

        setAllComponentsRendered(true);
        setIsRendering(false);
        setShowLoader(false);
        setCurrentRendering("");
    };

    useEffect(() => {
        renderComponentsSequentially();
    }, [template]);

    return (
        <div className='root-container'>
            {isRendering && showLoader && (
                <Modal show={isRendering} style={{ width: "500px", height: "250px", opacity: "0.8", display: "flex", flexDirection: "column", justifyContent: "center" }} onClose={() => setShowLoader(false)}>
                    <img src={Loader} height={120} width={120} style={{ margin: 'auto', marginTop: "-2rem" }} />
                    <h4 style={{ marginTop: "0", textAlign: 'center', translate: "0 -1.5rem" }}>Building {currentRendering}</h4>
                </Modal>
            )}

            {template && renderedComponents?.map((section, index) => (
                <DynamicComponentLoader
                    key={index}
                    type={section.type}
                    delay={index * 0.3} // Reduced delay for smoother appearance
                    ref={(el) => componentRefs.current[index] = el}
                    onLoad={() => setIsRendering(true)} // Show modal when component starts loading
                    onAnimationComplete={() => {
                        // Handle the modal text update and focus at the same time
                        focusNextComponent(index);
                        if (index === renderedComponents.length - 1) {
                            setIsRendering(false); // Hide modal after the last component
                        }
                    }}
                    {...section}
                />
            ))}
        </div>
    );
};

export default Builder;
