import React from "react";
import "./Testimonials.scss";
import SingleSlider from "../../../Components/SingleSlider/SingleSlider";
import ProfileIcon1 from "../../../Assets/Images/V3/person1.png";
import ProfileIcon2 from "../../../Assets/Images/V3/person2.png";
import ProfileIcon3 from "../../../Assets/Images/V3/person3.png";
import ProfileIcon4 from "../../../Assets/Images/V3/person4.png";

const Testimonials = () => {
  const slides = [
    {
      rating: "5.0",
      msg: "Aiktech India has transformed the way we run our online store. Their team helped us streamline everything, making our website faster and more reliable. Our customers are happier, and so are we! They really understand what a growing business like ours needs.",
      profileIcon: ProfileIcon1,
    },
    {
      rating: "4.0",
      msg: "Aiktech India designed a perfect solution for managing our invoices and payments. They've made the process so easy, saving us time and effort every day. It's clear they care about delivering exactly what their clients need, and they’ve been great partners in our growth.",
      profileIcon: ProfileIcon2,
    },
    {
      rating: "3.0",
      msg: "We asked Aiktech India to create an app for our supply chain operations, and the results were outstanding. It's easy to use, works even when we're offline, and has improved our efficiency across the board. They listened to what we needed and delivered beyond our expectations.",
      profileIcon: ProfileIcon3,
    },
    {
      rating: "5.0",
      msg: "Aiktech India has been incredible to work with. They made sure our systems run smoothly and helped us connect better with our customers through notifications and updates. They’re reliable, easy to work with, and truly care about our success.",
      profileIcon: ProfileIcon4,
    },
  ];
  return (
    <div className="home-v3-testimonials">
      <p className="home-v3-testimonials-heading">Our Reviews</p>
      <p style={{ textAlign: "center" }}>
        We like to keep working, so we let our customers speak.
      </p>
      <div className="review-container">
        {/* {slides.map((review, index) => (
                    <div className='card' key={index}>
                        <div className='rating-container'></div>
                        <p>{review.msg}</p>
                    </div>
                ))} */}
        <SingleSlider slides={slides} />
      </div>
    </div>
  );
};

export default Testimonials;
