import React, { useState } from 'react';
import "./AddStock.scss";
import { add_Entity } from '../../../Api/cmsAPI';
import { toast } from 'react-toastify';

const AddEntityModal = ({ setOpenAddEntityModal }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [GSTIN, setGSTIN] = useState('');
    const [DLNo, setDLNo] = useState('');
    const [CIN, setCIN] = useState('');
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [tenant, setTenant] = useState('');

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const payload = {
                title: title,
                description: description,
                is_active: isActive,
                gstin: GSTIN,
                dl_no: DLNo,
                cin: CIN,
                address: address,
                phone_number: phoneNumber,
                // tenant: tenant
            }
            await add_Entity(payload);
            setOpenAddEntityModal(false);
            toast.success("New Entity Added Successfully");
        } catch (err) {
            console.error(err.message);
            toast.error("Error while Adding New Entity");
        }
    };

    return (
        <>
            <h3 style={{ textAlign: 'center', position: "relative", bottom: "10px" }}>Add New Entity</h3>
            <form onSubmit={handleSubmit} className="space-y-4 add-entity-form">
                <div>
                    <label>Title:</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="w-full p-1 border rounded"
                    //   required
                    />
                </div>

                <div>
                    <label>Description:</label>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="w-full p-1 border border-gray-400 rounded"
                        required
                    />
                </div>

                <div style={{ width: '180px', marginLeft: "80px" }}>
                    <label>Active:</label>
                    <input
                        type="checkbox"
                        checked={isActive}
                        onChange={(e) => setIsActive(e.target.checked)}
                        style={{ width: "fitContent", height: "20px", cursor: "pointer" }}
                        required
                    />
                    <span>{isActive ? 'Yes' : 'No'}</span>
                </div>

                <div>
                    <label>GSTIN:</label>
                    <input
                        type="text"
                        value={GSTIN}
                        onChange={(e) => setGSTIN(e.target.value)}
                        className="w-full p-1 border border-gray-400 rounded"
                        required
                    />
                </div>

                <div>
                    <label>DL No:</label>
                    <input
                        type="text"
                        value={DLNo}
                        onChange={(e) => setDLNo(e.target.value)}
                        className="w-full p-1 border border-gray-400 rounded"
                        required
                    />
                </div>

                <div>
                    <label>CIN:</label>
                    <input
                        type="text"
                        value={CIN}
                        onChange={(e) => setCIN(e.target.value)}
                        className="w-full p-1 border border-gray-400 rounded"
                        required
                    />
                </div>

                <div>
                    <label>Address:</label>
                    <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        className="w-full p-1 border border-gray-400 rounded"
                        required
                    />
                </div>

                <div>
                    <label>Phone Number:</label>
                    <input
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="w-full p-1 border border-gray-400 rounded"
                        required
                    />
                </div>
                {/* <div>
                    <label>Tenant Name:</label>
                    <input
                        type="text"
                        value={tenant}
                        onChange={(e) => setTenant(e.target.value)}
                        className="w-full p-1 border rounded"
                        required
                    />
                </div> */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button type="submit" className="form-button">
                        Add
                    </button>
                </div>
            </form>
        </>
    );
};

export default AddEntityModal;
