import React, { useState, useEffect, useRef } from "react";
import fuzzysort from "fuzzysort";
import axios from "axios";
import "./AddStock.scss";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { errorMsg } from "../../../Utils";
import { FaSignInAlt } from "react-icons/fa";

import logo from "../../../Assets/Images/phurti_logo.png";

// apis
import Loader from "../Components/Loader";
import {
  fetch_entities,
  fetch_hsnCodes,
  fetchInventorys,
  sellableProductSearch,
} from "../../../Api/cmsAPI";
import { getBaseUrl } from "../../../Lib/NetworkHandler";
import { addStock } from "../../../Api/cmsAPI";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";

// components
import Modal from "../../../Components/Modal";
import Searchsuggestion from "./Searchsuggestion";
import Addinventory from "./Addinventory";
import AddUnit from "./AddUnit";
import AddProduct from "./AddProduct";
import { LabelledInput, LabelledSelect } from "../../../Components";
import SearchSuggestion from "../Components/SearchSuggestion/SearchSuggestion";
import HoverComponent from "../Components/HoverComponent/HoverComponent";
import useDebounce from "../../../Utils/debounce-utils";
import AddEntityModal from "./AddEntityModal";
import AddHsnCodeModal from "./AddHsnCodeModal";
import StockTable from "./StockTable";
import { CheckPermissionFunc } from "../../../Utils/general-utils";


export function formattedInputs(list, myLabel, myValue = "id") {
  const newList = [];
  list.map((item) =>
    newList.push({ 'label': item?.[myLabel], 'value': item?.[myValue] })
  )
  return newList;
}
const mapStateToProps = ({ stockdropdown, productsearch, products = {} }) => ({
  stockdropdown,
  productsearch,
  products,
});

export default function AddStock() {
  const {
    stockdropdown: { list: stockdropdownList },
    productsearch: {
      results: productsearch = [],
      loading: load,
      state: api_state,
    },
  } = useSelector(mapStateToProps);
  const {
    products: { list: fullProductList = [] },
  } = useSelector(mapStateToProps);
  const [inventory_id, setInventoryId] = useState(null);
  const [cartData, setCartData] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [barcode, setBarcode] = useState("");
  const [product, setProduct] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [tempProduct, setTempProduct] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [unit, setUnit] = useState("4");
  const [inventory, setInventory] = useState("");
  const [pricePerProduct, setPricePerProduct] = useState("");
  const [items, setItems] = useState([]);
  const [productSuggestions, setProductSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inventoryname, setInventoryname] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [productRemaining, setProductRemaining] = useState("NA");
  const [productAddress, setProductAddress] = useState("NA");
  const [expiry, setExpiry] = useState(null);
  const [batch_number, setBatchNumber] = useState(null);
  const [allInventories, SetAllInventories] = useState(null);
  const [allproducts, setAllProducts] = useState([]);
  const [addStockModal, toggleAddStockModal] = useState(false);

  const [serial_number, setSerialNumber] = useState(null);
  const [catalogue_number, setCatalogueNumber] = useState(null);
  const [purchase_trade_rate, setPurchaseTradeRate] = useState(null);
  const [manufacturer_date, setManufacturerDate] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [mrp, setMrp] = useState("");
  const [entity, setEntity] = useState("");
  const [tcs, setTcs] = useState("");
  const [packaging, setPackaging] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [saleRate, setSaleRate] = useState("");
  const [discount, setDiscount] = useState("");
  const [gst, setGst] = useState("");
  const [openAddEntityModal, setOpenAddEntityModal] = useState(false);
  const [openAddHsnCodeModal, setOpenAddHsnCodeModal] = useState(false);
  const [allInvoices, setAllInvoices] = useState([]);
  const [manufacturingDate2, setManufaturingDate2] = useState();
  const [expiryDate2, setexpiryDate2] = useState();
  const [includeGst, setIncludeGst] = useState(false);
  const [gstOnPackaging, setGstOnPackaging] = useState("");
  const [activeProductIndex, setActiveProductIndex] = useState(-1);


  const inventoryHandler = async (e) => {
    setInventory(e.target.value);
    allInventories?.map(async (i) => {
      if (i.id === Number(e.target.value)) {
        setInventoryname(i.name);

        let config = {
          params: {
            name: i.name,
          },
          headers: {
            "Content-Type": "application/json",
          },
        };

        toggleLoadingProduct(true);

        await axios
          .get(`${getBaseUrl()}/api/shop/inventory/id/`, config)
          .then((response) => {
            setInventoryId(response.data.data);
          })
          .catch((err) => { });

        config = {
          params: {
            inv: e.target.value,
          },
        };
        await axios
          .get(`${getBaseUrl()}/api/shop/products/data/`, config)
          .then((response) => {
            setAllProducts(response.data.data);

            // setAllSearchItems(response.data.data);
          })
          .catch((err) => { });

        setAllSearchItems(fullProductList);
        toggleLoadingProduct(false);

        return 0;
      }
    });
  };

  // to get all ivnvoices list for invoice number input field
  const fetchAllInvoices = async () => {
    try {
      const baseurl = getBaseUrl();
      const { data } = await axios.get(`${baseurl}/api/shop/purchase/`);
      setAllInvoices(data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchAllInvoices();
  }, []);
  useEffect(() => {
    const invenrorys_promise = fetchInventorys();
    invenrorys_promise
      .then((response) => {
        SetAllInventories(response.data.message);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, []);

  useEffect(() => {
    if (inventory == null) {
      // alert("Inventory Not Found!")
      setInventoryname("NOT FOUND");
      setshowModal(true);
    }
  }, [stockdropdownList.inventory]);
  //loader
  const [loader, showloader] = useState(false);

  //image
  const [image, setImage] = useState(null);

  const [modal, setModal] = useState(false);
  const [option, setOption] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const dispatch = useDispatch();
  const fetchStockDropdown = async () => {
    dispatch(actionsCreator.FETCH_STOCK_DROPDOWN());
  };

  const fetchProductsearch = async (id) => {
    dispatch(actionsCreator.FETCH_ALL_PRODUCTS(id));
  };

  const closeModal = (value, value1) => {
    setModal(value ? true : false);
    setDropdown(value1 ? true : false);
  }; // Closing the modal

  useEffect(() => {
    fetchStockDropdown();
  }, [dropdown]);

  useEffect(() => {
    fetchStockDropdown();
  }, []);

  const onChangeHandler = (e, key) => {
    const value = e.target.value;
    if (key === "PRODUCT") {
      setProduct(value);
    } else if (key === "PRICE_PER_PRODUCT") {
      setPricePerProduct(value);
    } else if (key === "UNIT") {
      setUnit(value);
    } else if (key === "INVENTORY") {
      setInventory(value);
    } else if (key === "ADDED_BY") {
      setAddedBy(value);
    } else if (key === "QUANTITY") {
      setQuantity(value);
    } else if (key === "BARCODE") {
      setBarcode(value);
    } else if (key === "MANUFACTURER") {
      setManufaturingDate2(value);
      let j = value + "-01";
      setManufacturerDate(j);
    } else if (key === "CATALOGUE") {
      setCatalogueNumber(value);
    } else if (key === "PTR") {
      setPurchaseTradeRate(value);
    } else if (key === "INVOICE_NO") {
      setInvoiceNumber(value);
    } else if (key === "INVOICE_DATE") {
      setInvoiceDate(value);
    }
  };

  const isEqual = (item, id, inventory_id) =>
    item.id === id && item.inventory_id === inventory_id;
  //ADDING ALL THE ITEM INFORMATION IN THE LIST
  const addItem = () => {
    if (unit == "") {
      setUnit("4");
    } else if (
      quantity == "" ||
      product.id == "" ||
      unit == "" ||
      inventory == "" ||
      product == "" ||
      purchase_trade_rate == "" ||
      saleRate == "" ||
      mrp == "" ||
      invoiceNumber == "" ||
      invoiceDate == null ||
      hsnCode == "" ||
      entity == "" ||
      discount == "" ||
      gst == "" ||
      tcs == ""
    ) {
      toast.error("Please add detail correctly.");
    } else {
      const [...productExistingDetails] = allproducts.filter(
        (prod) => prod.product_name == product.product_name
      );
      if (product && quantity) {
        const entered_product = allproducts.find((item) =>
          isEqual(item, parseInt(product.id), inventory_id)
        );

        let payload = {
          productInfo: product,
          stock_product: product?.id,
          product_image: product?.photo,
          stock_quantity: quantity,
          stock_unit: unit,
          inventory: inventory_id,
          added_by: addedBy,
          temp_stock_product: product?.product_name,
          description: product?.description,
          barcode: barcode,
          product_remaining:
            productExistingDetails[0]?.quantity_remaining || "Not In SI",
          product_address: product?.address,
          batch_number: batch_number,
          serial_number: serial_number,
          manufacturer_date: manufacturer_date,
          expiry: expiry,
          catalogue_number: catalogue_number,
          purchase_trade_rate: purchase_trade_rate,
          purchase_invoice_number: invoiceNumber,
          purchase_invoice_date: invoiceDate,
          mrp: mrp,
          hsnCode: hsnCode,
          entity: entity,
          tcs: tcs,
          packaging: packaging,
          gstOnPackaging: gstOnPackaging,
          saleRate: saleRate,
          discount: discount,
          gst: gst,
          netRate: (
            purchase_trade_rate -
            discount +
            (gst / 100) * purchase_trade_rate
          ).toFixed(2),
        };

        if (expiry) {
          payload["expiry"] = expiry;
        }

        let cartObj = [...cartData];

        cartObj.unshift(payload);
        setCartData(cartObj);
        localStorage.setItem("localdata", JSON.stringify([...cartObj]));
        clearInputs();

        setImage(product?.photo);
      }
    }
  };
  useEffect(() => {
    const storedArrayString = localStorage.getItem("localdata");
    if (storedArrayString) {
      const storedArray = JSON.parse(storedArrayString);
      if (storedArray?.length > 0) {
        setCartData(storedArray);
        setInventory(storedArray[0]?.inventory);
        setInventoryId(storedArray[0]?.inventory);
        setAllSearchItems(fullProductList);
        // inventoryHandler(storedArray[0].inventory)
        allInventories &&
          allInventories?.forEach((i) => {
            if (i.id === Number(storedArray[0].inventory)) {
              setInventoryname(i.name);
            }
          });
      }
    }
  }, []);
  useEffect(() => {
    if (allInventories?.length > 0) {
      // Check if allInventories is populated
      allInventories.forEach((i) => {
        if (i.id === Number(inventory_id)) {
          setInventoryname(i.name);
        }
      });
    }
  }, [allInventories]); // Add allInventories as a dependency

  //editing items
  const editItem = () => {
    if (
      quantity === "" ||
      product === "" ||
      unit === "" ||
      inventory === "" ||
      purchase_trade_rate == "" ||
      invoiceNumber == "" ||
      invoiceDate == null ||
      hsnCode == "" ||
      saleRate == "" ||
      mrp == "" ||
      discount == "" ||
      gst == "" ||
      entity == "" ||
      tcs == "" ||
      packaging == ""
    ) {
      toast.error("Please add detail correctly.");
    } else {
      const [...productExistingDetails] = allproducts.filter(
        (prod) => prod.product_name == product.product_name
      );
      if (product) {
        let payload = {
          productInfo: product,
          stock_product: product?.id,
          product_image: product?.photo,
          stock_quantity: quantity,
          stock_unit: unit,
          inventory: inventory_id,
          added_by: addedBy,
          temp_stock_product: product?.product_name,
          description: product?.description,
          barcode: barcode,
          product_remaining:
            productExistingDetails[0]?.quantity_remaining || "Not In SI",
          product_address: product?.address,
          batch_number: batch_number,
          serial_number: serial_number,
          manufacturer_date: manufacturer_date,
          expiry: expiry,
          catalogue_number: catalogue_number,
          purchase_trade_rate: purchase_trade_rate,
          purchase_invoice_number: invoiceNumber,
          purchase_invoice_date: invoiceDate,
          mrp: mrp,
          hsnCode: hsnCode,
          discount: discount,
          gst: gst,
          saleRate: saleRate,
          entity: entity,
          netRate: (
            purchase_trade_rate -
            discount +
            (gst / 100) * purchase_trade_rate
          ).toFixed(3),
          tcs: tcs,
          packaging: packaging,
          gstOnPackaging: gstOnPackaging,
        };

        let cartObj = cartData;
        cartObj[editIndex] = payload;
        setCartData(cartObj);
        setTempProduct("");
        clearInputs();
      }

      setEditIndex(null);
      setEditModal(false);
    }
  };

  //setting values of cart
  const setValues = (index = editIndex) => {
    setProduct(cartData[index].productInfo);
    setUnit(cartData[index].stock_unit);
    setInventory(cartData[index].inventory);
    setQuantity(cartData[index].stock_quantity);
    setTempProduct(cartData[index].temp_stock_product);
    setProductDescription(cartData[index].description);
    setImage(cartData[index].product_image);
    setBarcode(cartData[index].barcode);
    setBatchNumber(cartData[index].batch_number);
    setSerialNumber(cartData[index]?.serial_number);
    setManufacturerDate(cartData[index]?.manufacturer_date);
    setCatalogueNumber(cartData[index]?.catalogue_number);
    setPurchaseTradeRate(cartData[index]?.purchase_trade_rate);
    setExpiry(cartData[index].expiry);
    setDiscount(cartData[index]?.discount);
    setGst(cartData[index]?.gst);
    setHsnCode(cartData[index]?.hsnCode);
    setSaleRate(cartData[index]?.saleRate);
    setMrp(cartData[index]?.mrp);
  };

  //clearing all the inputs
  const clearInputs = () => {
    setPricePerProduct("");
    setProductDescription("");
    setQuantity("");
    setProduct("");
    setUnit("");
    setTempProduct("");
    setBarcode("");
    setImage(null);
    setBarcodeQuery("");
    setExpiry();
    setBatchNumber("");
    setCatalogueNumber("");
    setManufacturerDate(null);
    setPurchaseTradeRate("");
    setSerialNumber("");
    setMrp("");
    setHsnCode("");
    setSaleRate("");
    setDiscount("");
    setGst("");
  };

  //deleting the added item
  const deleteItem = (index) => {
    let cartObj = [...cartData];
    cartObj.splice(index, 1);
    setCartData(cartObj);
    localStorage.setItem("localdata", JSON.stringify(cartObj));
  };

  //API for fetching product from barcode or product name.
  const getProduct = async (e) => {
    try {
      let product_name = e.target.value;
      let barcode = e.target.barcode;

      if (product_name || barcode) {
        if (product_name) {
          setShowSuggestions(true);
          let regex = new RegExp(`${product_name}`, "i");
          let suggestion = allproducts.filter((o) =>
            regex.test(o.product_name)
          );

          let sortedSuggestion = fuzzysort.go(
            product_name.toLowerCase(),
            suggestion,
            { key: "product_name" }
          );

          suggestion = sortedSuggestion.map((i) => i.obj);
          setProductSuggestions(suggestion);
          setTempProduct(product_name);
          setBarcode("");

          setProduct(product_name);
        } else if (barcode) {
          let product_from_barcode = allproducts.filter(
            (i) => i.barcode === barcode.replace("\n", "")
          );
          if (product_from_barcode.length > 0) {
            setTempProduct(product_from_barcode[0].product_name);
            setProduct(product_from_barcode[0].id);
            setMarketPrices(product_from_barcode[0].market_price);
            setPrices(product_from_barcode[0].price);
            setProductDescription(product_from_barcode[0].description);
            setBarcodeQuery(product_from_barcode[0].product_name);
            setImage(product_from_barcode[0].photo);
            setProductRemaining(
              product_from_barcode[0].remaining_products &&
              product_from_barcode[0].remaining_products.map((i) =>
                i.inventory_id == inventory ? i.product_remaining : null
              )
            );
            // setProductAddress(product_from_barcode[0].remaining_products && product_from_barcode[0].map(i => (i.inventory_id==inventory? i.address: null)));
            setProductAddress(
              product_from_barcode[0].inventory_id === inventory
                ? product_from_barcode[0].address
                : null
            );

            let searchPriceinput = document.querySelector("#price_per_product");
            if (searchPriceinput) {
              searchPriceinput.focus();
            }
          } else {
            // setBarcodeQuery("");
            // setProduct(e.target.value)
          }
        }
      }
    } catch (error) {
      setProductSuggestions([]);
    }
  };

  //using debouncing
  const handleSearchProduct = getProduct;

  //filling data after selecting from the suggestions list
  const handleSuggestion = (e) => {
    setShowSuggestions(false);
    document.querySelector(".suggestion_input").value =
      e.target.getAttribute("data-producttitle");

    setProduct(e.target.getAttribute("data-productid"));

    setProductDescription(e.target.getAttribute("data-productdescription"));

    setTempProduct(e.target.getAttribute("data-producttitle"));

    setPrices(e.target.getAttribute("data-product-price"));

    setMarketPrices(e.target.getAttribute("data-product-market-price"));
    setImage(e.target.getAttribute("data-product-image"));
    setBarcode(e.target.getAttribute("data-product-barcode"));
    setProductRemaining(
      e.target.getAttribute("data-product-remaining")
        ? parseInt(
          e.target.getAttribute("data-product-remaining").replace(/,/g, "")
        )
        : e.target.getAttribute("data-product-remaining")
    );

    setProductAddress(
      e.target.getAttribute("data-product-address")
        ? e.target.getAttribute("data-product-address").replace(/,/g, "")
        : e.target.getAttribute("data-product-address")
    );

    let searchPriceinput = document.querySelector("#price_per_product");
    if (searchPriceinput) {
      searchPriceinput.focus();
    }
  };

  const history = useHistory();


  //adding all the items in the stocks
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      quantity !== "" &&
      unit !== "" &&
      product !== "" &&
      inventory !== "" &&
      pricePerProduct !== "" &&
      purchase_trade_rate != ""
      // catalogue_number != "" &&
      // manufacturer_date != null &&
      // serial_number != "" &&
      // expiry != null &&
      // batch_number != "" &&
      // barcode != ""
    ) {
      let temp = {
        stock_quantity: quantity,
        stock_unit: unit,
        stock_product: product,
        inventory: inventory_id,
        mrp: mrp,
        barcode: barcode,
        batch_number: batch_number,
        expiry: expiry,
        serial_number: serial_number,
        manufacturer_date: manufacturer_date,
        catalogue_number: catalogue_number,
        purchase_trade_rate: purchase_trade_rate,
        discount: discount,
        gst: gst,
        saleRate: saleRate,
      };
      cartData.push(temp);
    }

    if (!cartData || cartData.length === 0) {
      toast.error("Please add stock correctly.");
    } else {
      // API calls for adding data to the backend
      clearInputs();
      let stockData = {
        purchaseData: {
          invoice_number: invoiceNumber,
          invoice_date: invoiceDate,
          entity: entity,
          tcs: tcs,
          packaging: packaging,
          gst_on_packaging: gstOnPackaging,
        },
        data: cartData,
      };

      if (cartData.length === 0) {
        toast.error("Please add required details.");
      }

      showloader(true);

      addStock(stockData)
        .then((res) => {
          if (res.data.status === 201) {
            toast.success("Stocks Added Successfully!");
            showloader(false);
            setCartData([]);
            localStorage.removeItem("localdata");
          } else if (res.data.status === 400) {
            toast.error("Please fill all fields correctly!");
          }
        })
        .catch((err) => {
          const { purchaseData } = errorMsg(err);
          if (purchaseData) {
            let index = 0;
            let errorString = '';

            for (let i in purchaseData) {
              index++;
              let message = purchaseData[i]?.[0];
              if (message) {
                errorString += message.replace('This', i).replace(".", "") + " in item " + index + " in the Cart.\n";
              } else {
                errorString += `Error with item ${index} in the Cart.\n`; // Handles cases where purchaseData[i][0] is missing
              }
            }
            toast.error(errorString, {
              autoClose: 5000,
            });
          } else {
            toast.error(errorMsg(err), {
              autoClose: 3000,
            });
          }
        });
      showloader(false);
    }
  };
  const [editModal, setEditModal] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editPlaceHolder, setEditPlaceHolder] = useState("Search Results");
  const [prices, setPrices] = useState("");
  const [marketPrices, setMarketPrices] = useState("");
  useEffect(() => {
    if (tempProduct === "" || tempProduct === null) {
      setPrices("");
      setMarketPrices("");
    }
  }, [tempProduct]);

  useEffect(() => {
    if (!editModal) setEditPlaceHolder("Search Products");
    else setEditPlaceHolder(cartData[editIndex].temp_stock_product);
  }, [editModal, editIndex]);

  useEffect(() => {
    let barcode = document.querySelector(".product_barcode");
    if (barcode) barcode.focus();
    // setImage(logo);
  }, []);

  const [barcodeQuery, setBarcodeQuery] = useState(null);

  //checking for new line in the string here using for barcode
  function isNewLine(str, char = "\n") {
    return str.indexOf(char) > -1;
  }

  //calling the getProduct function to fetch product through barcode
  useEffect(() => {
    if (barcode !== "" && barcode && isNewLine(barcode)) {
      let data = {
        target: {
          barcode: barcode,
        },
      };
      getProduct(data);
      setBarcode(removeNewLine(barcode));
    }
  }, [barcode]);

  const removeNewLine = (str) => {
    let ans = str.replace("\n", "");
    return ans;
  };

  // side actions to fetch hsn code list

  useEffect(() => {
    fetch_hsnCodes()
      .then(({ data }) => {
        setAllHsnCodes(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [hsnCode]);
  //barcodeQuery is the product name fetched from backend through barcode
  useEffect(() => {
    let searchProductinput = document.querySelector("#product_name");
    if (searchProductinput && barcodeQuery) {
      searchProductinput.focus();
      searchProductinput.value = barcodeQuery;
    }
    setBarcodeQuery(null);
  }, [barcodeQuery]);

  const newArr = new Array(4).fill(0);

  // handle SearchPart
  const [query, setQuery] = useState("");

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  };

  const searchRef = useRef(null);
  const entityRef = useRef(null);
  const hsnCodeRef = useRef(null);
  const invoiceRef = useRef(null);
  const [allSearchItems, setAllSearchItems] = useState([]);
  const [allEntities, setAllEntities] = useState([]);
  const [allHsnCodes, setAllHsnCodes] = useState([]);
  const [showHsnCodeSearch, setShowHsnCodeSearch] = useState(false);
  const [showInvoiceSearch, setShowInvoiceSearch] = useState(false);
  const [activeInvoiceIndex, setActiveInvoiceIndex] = useState(-1);

  // const fetchSearchProducts = async (inv_id) => {
  //   const config = {
  //     params: {
  //       inv: inv_id,
  //     },
  //   };

  //   try {
  //     axios
  //       .get(`${getBaseUrl()}/api/shop/products/data/`, config)
  //       .then((response) => {
  //         setAllProducts(response.data.data);
  //         setAllSearchItems(response.data.data);
  //       })
  //       .catch((err) => {});
  //   } catch (e) {}

  // };

  // to handle on click outside in inovoice search input
  useEffect(() => {
    // Function to handle click outside
    const handleClickOutside = (event) => {
      if (
        invoiceRef.current &&
        !invoiceRef.current.contains(event.target) &&
        !invoiceRef.current.contains(event.target)
      ) {
        setShowInvoiceSearch(false);
      }
    };
    if (showInvoiceSearch) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showInvoiceSearch]);
  const [loadSearchingProduct, toggleLoadingProduct] = useState(false);
  const debouncedQuery = useDebounce(product, 400);
  const [showSearch, toggleSearch] = useState(false);


  const handleClickOutside = (event) => {
    const myElement = searchRef?.current;
    if (myElement && !myElement.contains(event.target)) {
      toggleSearch(false);
      setShowHsnCodeSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  const handleInvoiceChange = async (e) => {
    setInvoiceNumber(e.target.value);
    setShowInvoiceSearch(true);
    const invoice = allInvoices?.filter(
      (item) =>
        item?.invoice_number.toLowerCase() === e.target.value.toLowerCase()
    );
    if (invoice.length === 1) {
      setInvoiceDate(invoice[0]?.invoice_dated);
      setPackaging(invoice[0]?.packaging);
      setEntity(invoice[0]?.entity);
      setTcs(invoice[0]?.tcs);
      setGstOnPackaging(invoice[0]?.gst_on_packaging);
    } else {
      setInvoiceDate("");
      setPackaging("");
      setEntity("");
      setTcs("");
      setGstOnPackaging("");
    }
  };
  let isProcessing = false;

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter" || e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();  // Prevent the default action (form submission)
        e.stopPropagation(); // Stop the event from propagating up to the form
      }
      const list = allSearchItems
        ?.filter((item) => {
          let onSearchValues = [];
          onSearchValues.push(
            item?.product_name,
            ...item?.alternate_names,
            item?.catalogue_number,
            item?.previous_catalogue_number
          );
          onSearchValues = onSearchValues?.map((val) =>
            val?.toLowerCase()
          );
          const query = typeof debouncedQuery === 'string' ? debouncedQuery.toLowerCase() : '';
          return onSearchValues?.some((val) =>
            val?.includes(query)
          );
        })
      if (e.key === "ArrowDown") {
        setActiveProductIndex((prevIndex) =>
          prevIndex < list.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (e.key === "ArrowUp") {
        setActiveProductIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
      } else if (e.key === "Enter" && activeProductIndex !== -1) {
        const selectedItem = list[activeProductIndex];
        if (selectedItem) {
          setProduct(selectedItem);
          setCatalogueNumber(selectedItem?.catalogue_number);
          setHsnCode(selectedItem?.hsn_code?.code);
          setGst(selectedItem?.hsn_code?.gst);
          toggleSearch(false);
        }
      }
    };
    if (showSearch) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [activeProductIndex, allSearchItems, showSearch]);
  const fetchAllEntities = async () => {
    try {
      const { data } = await fetch_entities();
      setAllEntities(data);
    } catch (err) {
      console.error(err);
    }
  }
  useEffect(() => {
    fetchAllEntities();
  }, [])

  useEffect(() => {
    const formElement = document.getElementById("form");

    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent the default form submission

        if (!isProcessing) {
          isProcessing = true;
          document.getElementById("button1").click();
          setTimeout(() => {
            isProcessing = false;
          }, 500); // Adjust timeout as needed
        }
      }
    };

    formElement?.addEventListener("keypress", handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      formElement?.removeEventListener("keypress", handleKeyPress);
    };
  }, []);
  useEffect(() => {
    let isProcessing = false;

    const handleKeyPress = (event) => {
      if (event.key === "Enter" && event.shiftKey) {
        event.preventDefault(); // Prevent the default behavior

        if (!isProcessing) {
          isProcessing = true;
          document.getElementById("button2").click();
          setTimeout(() => {
            isProcessing = false;
          }, 500); // Adjust timeout as needed
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter" || e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();  // Prevent the default action (form submission)
        e.stopPropagation(); // Stop the event from propagating up to the form
      }
      const list = allInvoices
        ?.filter((item) => {
          return item?.invoice_number
            ?.toLowerCase()
            ?.includes(invoiceNumber.toLowerCase());

        })
      if (e.key === "ArrowDown") {
        setActiveInvoiceIndex((prevIndex) =>
          prevIndex < list.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (e.key === "ArrowUp") {
        setActiveInvoiceIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
      } else if (e.key === "Enter" && activeInvoiceIndex !== -1) {
        const selectedItem = list[activeInvoiceIndex];
        if (selectedItem) {
          setInvoiceNumber(selectedItem?.invoice_number);
          setInvoiceDate(selectedItem?.invoice_dated);
          setPackaging(selectedItem?.packaging);
          setEntity(selectedItem?.entity);
          setTcs(selectedItem?.tcs);
          setGstOnPackaging(selectedItem?.gst_on_packaging);
          setShowInvoiceSearch(false);
        }
      }
    };
    if (showInvoiceSearch) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [activeInvoiceIndex, allInvoices, showInvoiceSearch]);

  // (openAddEntityModal || openAddHsnCodeModal) {
  //   return (
  //     <div
  //       style={{
  //         width: "60vw",
  //         maxHeight: "500px",
  //         border: "2px solid #ccc",
  //         overflow: "auto",
  //         borderRadius: "10px",
  //         padding: "1rem",
  //         margin: "auto",
  //       }}
  //     >
  //       <button
  //         style={{
  //           position: "relative",
  //           left: "97%",
  //           top: "0",
  //           fontSize: "25px",
  //           width: "35px",
  //           height: "35px",
  //           border: "2px solid gray",
  //           borderRadius: "5px",
  //           zIndex: "2",
  //         }}
  //         onClick={() => {
  //           setOpenAddEntityModal(false);
  //           setOpenAddHsnCodeModal(false);
  //         }}
  //       >
  //         <span style={{ position: "relative", bottom: "5px" }}>x</span>
  //       </button>
  //       {openAddEntityModal ? (
  //         <AddEntityModal setOpenAddEntityModal={setOpenAddEntityModal} />
  //       ) : (
  //         <AddHsnCodeModal setOpenAddHsnCodeModal={setOpenAddHsnCodeModal} />
  //       )}
  //     </div>
  //   );
  // }

  return (
    <div
      className="w-full overflowY-scroll"
      style={{
        height: "90vh",
        // paddingBottom: "5rem",
        maxHeight: "100%",
        width: "100%",
        overflowY:'hidden'
      }}
    >
      <Modal show={openAddEntityModal} onClose={() => setOpenAddEntityModal(false)} 
        style = {{width:"800px"}}>
        <AddEntityModal setOpenAddEntityModal={setOpenAddEntityModal} />

      </Modal>
      <Modal show={openAddHsnCodeModal} onClose={() => setOpenAddHsnCodeModal(false)}
        style = {{width:"600px"}}>
          <AddHsnCodeModal setOpenAddHsnCodeModal={setOpenAddHsnCodeModal} />
      </Modal>
      <Modal show={loadSearchingProduct}>
        <div className="h-full w-full flex-column justify-center items-center">
          <Loader />
          <span>Please Wait. Products are being fetched!</span>
        </div>
      </Modal>

      <Modal show={loader}>
        <Loader message={"Please Wait. Adding Stocks!"}></Loader>
      </Modal>

      <Modal show={showModal}>
        <div className="home-page-modal">
          <h5>Inventory Not Found</h5>
        </div>
      </Modal>

      <Modal show={modal} onClose={() => setModal(false)}>
        {option === "inventory" ? (
          <Addinventory closeModal={closeModal} dropdown={dropdown} />
        ) : null}
        {option === "unit" ? (
          <AddUnit closeModal={closeModal} dropdown={dropdown} />
        ) : null}
        {option === "product" ? (
          <AddProduct
            setBarcodeQuery={setBarcodeQuery}
            setPrices={setPrices}
            setProductDescription={setProductDescription}
            setBarcode={setBarcode}
            closeModal={closeModal}
            dropdown={dropdown}
            allproducts={allproducts}
            setProduct={setProduct}
            setTempProduct={setTempProduct}
          />
        ) : null}
      </Modal>

      <div
        className="input-border small-input-padding flex-row"
        style={{ maxHeight: "fit-content", width: "100%" }}
      >
        <select
          onChange={inventoryHandler}
          value={inventory}
          className="flex-1"
        >
          <option>Select Inventory</option>

          {stockdropdownList &&
            allInventories &&
            allInventories?.map((i, index) => (
              <option value={i.id} key={index}>
                {i.name}
              </option>
            ))}
        </select>
      </div>

      <div
        className="w-full h-full"
        style={{ marginBottom: "20px", width: "100%", display: "flex", flexDirection: "column", flexGrow: '1' }}
      >
        <form
          onSubmit={handleSubmit}
          id="form"
          autoComplete="new-password"
          style={{ marginTop: "1rem", display: "flex", flexDirection: 'column', height: '100%', flexGrow: '1' }}
        >
          <div
            className="responsive-flex-row gap-10 form-top-container"
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "10px",
              borderBottom: "2px solid gray",
              paddingLeft: "5px",
              position: "relative",
              fontSize: "0.9em",
            }}
          >
            <div
              ref={invoiceRef}
              className="flex-1 relative flex-row justify-between"
              id="invoice-results"
            >
              <div className="flex-1" style={{ minWidth: "170px" }} >
                <LabelledInput
                  className={"SmallInput"}
                  label="Purchase Invoice No."
                  name={"invoiceNo"}
                  placeholder="Enter Invoice No..."
                  type="text"
                  value={invoiceNumber}
                  onChange={(e) => handleInvoiceChange(e)}
                  required={true}
                />
              </div>
              {showInvoiceSearch && allInvoices.length > 0 && (
                <HoverComponent
                  hoverRef={invoiceRef}
                  style={{
                    height: "170px",
                    width: "100%",
                    backgroundColor: "white",
                    border: "1.5px solid #f2f2f2",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    padding: "5px",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    position: "absolute",
                    top: "70px",
                    gap: "2px",
                  }}
                >
                  {allInvoices
                    ?.filter((item) => {
                      return item?.invoice_number
                        ?.toLowerCase()
                        ?.includes(invoiceNumber.toLowerCase());
                    })
                    .map((item, index) => {
                      return (
                        <ul
                          ref={(el) => {
                            if (activeInvoiceIndex === index) {
                              el?.scrollIntoView({ behavior: "smooth", block: "nearest" });
                            }
                          }}
                          onClick={() => {
                            setInvoiceNumber(item?.invoice_number);
                            setInvoiceDate(item?.invoice_dated);
                            setPackaging(item?.packaging);
                            setEntity(item?.entity);
                            setTcs(item?.tcs);
                            setGstOnPackaging(item?.gst_on_packaging);
                            setShowInvoiceSearch(false);
                          }}
                          key={index}
                          className="flex-row gap-10 border items-center"
                          onMouseEnter={() => setActiveInvoiceIndex(index)}
                          style={{ backgroundColor: activeInvoiceIndex == index && "#e3effe", }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && activeInvoiceIndex == index) {
                              setInvoiceNumber(item?.invoice_number);
                              setInvoiceDate(item?.invoice_dated);
                              setPackaging(item?.packaging);
                              setEntity(item?.entity);
                              setTcs(item?.tcs);
                              setGstOnPackaging(item?.gst_on_packaging);
                              setShowInvoiceSearch(false);
                            }
                          }}
                        >
                          <li
                            className=""
                            style={{ padding: "4px" }}
                          >
                            <div className="flex-row gap-10 items-center gap-10 text-small text-bold-sm flex-wrap">
                              <span style={{ paddingX: "5px" }}>
                                {item?.invoice_number}
                              </span>
                            </div>
                          </li>
                        </ul>
                      );
                    })}
                  {allInvoices.filter((item) => {
                    return item?.invoice_number
                      ?.toLowerCase()
                      ?.includes(invoiceNumber.toLowerCase());
                  }).length <= 0 && (
                      <div className="text-center w-full">No Item to show</div>
                    )}
                </HoverComponent>
              )}
            </div>
            <div
              style={{
                flex: "1",
                minWidth: "170px"
              }}
            >
              <LabelledInput
                className={"SmallInput"}
                label="Purchase Invoice Date"
                name={"invoiceDate"}
                type="date"
                value={invoiceDate}
                onChange={(e) => onChangeHandler(e, "INVOICE_DATE")}
                // error={showErrors ? errors["product_name"] : {}}
                required={true}
              />
            </div>
            <div
              ref={entityRef}
              className="flex-1 relative flex-row justify-between"
              id="entity-drop-container"
            >
              <div className="flex-1" style={{ minWidth: "230px" }}>
                <LabelledSelect
                  className="SmallInput"
                  label="Entity"
                  name="entity"
                  data={formattedInputs(allEntities, 'title', 'title')}
                  placeholder="Select Entity"
                  onSelect={setEntity}
                  values={entity}
                  multiSelect={false}
                  required={true}
                />
              </div>
            </div>
            <button
              className="add-entity-btn"
              onClick={() => setOpenAddEntityModal(true)}
            >
              +
            </button>
            <div className="flex-1" style={{ maxWidth: "160px" }}>
              <LabelledInput
                className={"SmallInput"}
                label="TCS"
                name={"tcs"}
                value={tcs}
                onChange={(e) => setTcs(e.target.value)}
                placeholder="Select TCS"
                required={true}
              />
            </div>
            <div className="flex-1" style={{ maxWidth: "160px" }}>
              <LabelledInput
                className={"SmallInput"}
                label="Packaging"
                name={"packaging"}
                value={packaging}
                onChange={(e) => setPackaging(e.target.value)}
                placeholder="Select Packaging"
                required={false}
              />
            </div>

            <div>
              <LabelledInput
                className={"SmallInput"}
                label="Gst On Packag."
                name={"gstOnPackaging"}
                value={gstOnPackaging}
                onChange={(e) => setGstOnPackaging(e.target.value)}
                placeholder="GST on Packaging"
                required={false}
              />
            </div>
          </div>
          {true && (
            <div
              className="flex-column gap-10 form-container"
              style={{ marginTop: "1rem" }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <div
                  ref={searchRef}
                  className="flex-1 relative flex-row justify-between"
                  style={{
                    flex: "2",
                  }}
                  id="product-results"
                >
                  <div className="flex-1">
                    <LabelledInput
                      className={"SmallInput"}
                      label="Search Product"
                      name={"search_product"}
                      placeholder="Search Products here..."
                      type="text"
                      // onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setCatalogueNumber("");
                          setHsnCode("");
                          setGst("");
                        }
                        toggleSearch(true);
                        setProduct(e.target.value);
                      }}
                      value={
                        product.product_name ? product.product_name : product
                      }
                      error={
                        product != "" && inventory_id === null
                          ? { msg: "Select Inventory" }
                          : {}
                      }
                      required={true}
                    />

                  </div>

                  {debouncedQuery.length >= 3 &&
                    showSearch &&
                    inventory_id !== null && (
                      <HoverComponent
                        hoverRef={searchRef}
                        style={{
                          height: "35vh",
                          width: "100%",
                          backgroundColor: "white",
                          border: "1px solid #f2f2f2",
                          overflowY: "scroll",
                          overflowX: "hidden",
                          padding: "5px",
                        }}
                      >
                        {allSearchItems
                          ?.filter((item) => {
                            let onSearchValues = [];
                            onSearchValues.push(
                              item?.product_name,
                              ...item?.alternate_names,
                              item?.catalogue_number,
                              item?.previous_catalogue_number
                            );
                            onSearchValues = onSearchValues?.map((val) =>
                              val?.toLowerCase()
                            );
                            return onSearchValues.some((val) =>
                              val?.includes(debouncedQuery.toLowerCase())
                            );
                          })
                          .map((item, index) => (
                            <ul
                              ref={(el) => {
                                if (activeProductIndex === index) {
                                  el?.scrollIntoView({ behavior: "smooth", block: "nearest" });
                                }
                              }}
                              onClick={() => {
                                setProduct(item);
                                setCatalogueNumber(item?.catalogue_number);
                                setHsnCode(item?.hsn_code?.code);
                                setGst(item?.hsn_code?.gst);
                                toggleSearch(false);
                              }}
                              className="flex-row gap-10 border items-center"
                              style={{
                                marginBottom: "1%",
                                cursor: "pointer",
                                backgroundColor: activeProductIndex == index && "#e3effe",
                              }}
                              onMouseEnter={() => setActiveProductIndex(index)}
                              onKeyDown={(e) => {
                                e.stopPropagation();
                                if (e.key === "Enter" && activeProductIndex == index) {
                                  setProduct(item);
                                  setCatalogueNumber(item?.catalogue_number);
                                  setHsnCode(item?.hsn_code?.code);
                                  setGst(item?.hsn_code?.gst);
                                  toggleSearch(false);
                                }
                              }}

                            >
                              <img
                                style={{
                                  height: "3.5rem",
                                  aspectRatio: "1",
                                  objectFit: "contain",
                                }}
                                className="text-small"
                                src={item.photo}
                                alt={"Product Img"}
                                loading={"lazy"}
                              />
                              <li className="">
                                <div className="flex-row gap-10 items-center gap-10 text-small text-bold-sm flex-wrap">
                                  <span>{item?.product_name}</span>
                                </div>
                                {item?.alternate_names && (
                                  <div
                                    style={{
                                      display: "flex",
                                      columnGap: "5px",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {item?.alternate_names?.map((nam) => (
                                      <span style={{ fontSize: "10px" }}>
                                        {nam}
                                      </span>
                                    ))}
                                  </div>
                                )}
                              </li>
                            </ul>
                          ))}
                        {allSearchItems?.filter((item) => {
                          let onSearchValues = [item?.["product_name"]];
                          onSearchValues.push(
                            ...item?.alternate_names,
                            item?.catalogue_number,
                            item?.previous_catalogue_number
                          );
                          onSearchValues = onSearchValues.map((val) =>
                            val?.toLowerCase()
                          );
                          return onSearchValues.some((val) =>
                            val?.includes(debouncedQuery?.toLowerCase())
                          );
                        }).length <= 0 && (
                            <div className="text-center w-full">
                              No Item to show
                            </div>
                          )}
                      </HoverComponent>
                    )}
                </div>

                <div
                  style={{
                    flex: "1",
                  }}
                >
                  <LabelledInput
                    className={"SmallInput"}
                    label="Barcode"
                    name={"barcode"}
                    placeholder="Enter Barcode here..."
                    type="text"
                    value={barcode}
                    onChange={(e) => onChangeHandler(e, "BARCODE")}
                  // error={showErrors ? errors["product_name"] : {}}
                  // required={true}
                  />
                </div>

                <div
                  className="flex-column"
                  style={{
                    flex: "1",
                    marginTop: "2.5px",
                  }}
                >
                  <div
                    className="text-medium"
                    style={{
                      fontWeight: "500",
                      position: "relative",
                      bottom: "2px",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Units <sup style={{ color: "red" }}>*</sup>
                  </div>
                  <div className="select-wrapper">
                    <select
                      onChange={(e) => onChangeHandler(e, "UNIT")}
                      value={unit}
                      className="flex-1 w-full"
                    >
                      <option>units</option>
                      {stockdropdownList &&
                        stockdropdownList.stock_unit &&
                        stockdropdownList.stock_unit.map(
                          (stock_unit, index) => (
                            <option value={stock_unit.id} key={index}>
                              {stock_unit.name}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                </div>

                <div
                  style={{
                    flex: "1",
                  }}
                >
                  <LabelledInput
                    className={"SmallInput"}
                    label="Catalogue No."
                    name={"catalogue_number"}
                    placeholder="Enter Catalogue number here..."
                    type="text"
                    value={catalogue_number}
                    onChange={(e) => onChangeHandler(e, "CATALOGUE")}
                  // error={showErrors ? errors["product_name"] : {}}
                  // required={true}
                  />
                </div>
                <div className="flex-1">
                  <LabelledInput
                    className={"SmallInput"}
                    label="Net Rate"
                    name={"net_rate"}
                    placeholder="Net Rate"
                    type="text"
                    value={
                      purchase_trade_rate && discount && gst
                        ? (
                          purchase_trade_rate -
                          discount +
                          (gst / 100) * purchase_trade_rate
                        ).toFixed(3)
                        : ""
                    }
                    disabled={true}
                    // error={showErrors ? errors["product_name"] : {}}
                    required={true}
                  />
                </div>
                <div className="flex-1">
                  <LabelledInput
                    className={"SmallInput flex-1"}
                    label="MRP"
                    name={"mrp"}
                    placeholder="Enter MRP here..."
                    type="text"
                    value={mrp}
                    onChange={(e) => setMrp(e.target.value)}
                    // error={showErrors ? errors["product_name"] : {}}
                    // list={"price_options"}
                    // id="price_per_product"
                    required={true}
                  />

                  {/* <input
                      className="priceperproduct"
                      type="text"
                      placeholder="Price"
                      onChange={(e) => onChangeHandler(e, "PRICE_PER_PRODUCT")}
                      value={pricePerProduct}
                      list={"price_options"}
                      id="price_per_product"
                    />

                    <datalist id="price_options">
                      <option value={prices}>Our Price</option>
                      {marketPrices && (
                        <option value={marketPrices}>Market Price</option>
                      )}
                    </datalist> */}
                </div>
                <div className="flex-1">
                  <LabelledInput
                    className={"SmallInput"}
                    label="Quantity"
                    name={"quantity"}
                    placeholder="Enter Quantity here..."
                    type="number"
                    value={quantity}
                    onChange={(e) => onChangeHandler(e, "QUANTITY")}
                    required={true}
                  // error={showErrors ? errors["product_name"] : {}}
                  />
                </div>
                <div className="flex-1">
                  <LabelledInput
                    className={"SmallInput"}
                    label="Discount"
                    name={"discount"}
                    placeholder="Discount in INR"
                    type="text"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    // error={showErrors ? errors["product_name"] : {}}
                    required={true}
                  />
                </div>
              </div>

              <div className="responsive-flex-row gap-10">
                <div
                  ref={hsnCodeRef}
                  className="flex-1 relative flex-row justify-between"
                  id="hsn-results"
                >
                  <div className="flex-1" style={{ minWidth: "120px" }}>
                    <LabelledInput
                      className={"SmallInput"}
                      label="HSN Code"
                      name={"hsn_code"}
                      placeholder="Search HSN Code"
                      value={hsnCode}
                      onChange={(e) => {
                        if (e.target.value == "") {
                          setGst("");
                        }
                        setHsnCode(e.target.value);
                        setShowHsnCodeSearch(true);
                      }}
                      required={true}
                    />
                  </div>
                  {showHsnCodeSearch && hsnCode.length > 0 && (
                    <HoverComponent
                      hoverRef={hsnCodeRef}
                      style={{
                        height: "170px",
                        width: "100%",
                        backgroundColor: "white",
                        border: "1.5px solid #f2f2f2",
                        overflowY: "auto",
                        overflowX: "hidden",
                        padding: "5px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {allHsnCodes
                        ?.filter((item) => {
                          return item?.code
                            ?.toLowerCase()
                            ?.includes(hsnCode.toLowerCase());
                        })
                        .map((item, index) => (
                          <ul
                            key={index}
                            onClick={() => {
                              setHsnCode(item?.code);
                              setGst(item?.gst);
                              setShowHsnCodeSearch(false);
                            }}
                            className="flex-row gap-10 border items-center"
                            style={{
                              marginBottom: "1%",
                            }}
                          >
                            <li className="" style={{ padding: "4px" }}>
                              <div className="flex-row gap-16 items-center gap-10 text-small text-bold-sm flex-wrap">
                                <span>{item?.code}</span>
                              </div>
                            </li>
                          </ul>
                        ))}
                      {allHsnCodes.filter((item) => {
                        return item?.code
                          ?.toLowerCase()
                          ?.includes(hsnCode.toLowerCase());
                      }).length <= 0 && (
                          <div className="text-center w-full" style={{ marginTop: "0.5rem" }}>
                            No Item to show
                          </div>
                        )}
                    </HoverComponent>
                  )}
                </div>
                <button
                  className="add-entity-btn"
                  style={{ top: "30px", right: "5px" }}
                  onClick={() => setOpenAddHsnCodeModal(true)}
                >
                  +
                </button>
                <div className="flex-1" style={{ maxWidth: "120px" }}>
                  <LabelledInput
                    className={"SmallInput"}
                    label="GST"
                    name={"gst"}
                    placeholder="% of PTR"
                    type="text"
                    value={gst}
                    onChange={(e) => setGst(e.target.value)}
                    // error={showErrors ? errors["product_name"] : {}}
                    required={true}
                  />
                </div>

                <div className="flex-1">
                  <LabelledInput
                    className={"SmallInput"}
                    label="Batch No."
                    name={"batch_number"}
                    placeholder="Enter Batch Number here..."
                    type="text"
                    value={batch_number}
                    onChange={(e) => setBatchNumber(e.target.value)}
                  // error={showErrors ? errors["product_name"] : {}}
                  // required={true}
                  />
                </div>
                <div className="flex-1">
                  <LabelledInput
                    className={"SmallInput"}
                    label="Serial No."
                    name={"serial_number"}
                    placeholder="Enter Serial Number here..."
                    type="text"
                    value={serial_number}
                    onChange={(e) => setSerialNumber(e.target.value)}
                  // error={showErrors ? errors["product_name"] : {}}
                  // required={true}
                  />
                </div>
                <div className="flex-1" style={{ maxWidth: "120px" }}>
                  <LabelledInput
                    className={"SmallInput"}
                    label="PTR"
                    name={"barcode"}
                    placeholder="Enter Purchase Trade rate here..."
                    type="text"
                    value={purchase_trade_rate}
                    onChange={(e) => onChangeHandler(e, "PTR")}
                    // error={showErrors ? errors["product_name"] : {}}
                    required={true}
                  />
                </div>
                <div className="flex-1">
                  <LabelledInput
                    className={"SmallInput"}
                    label="Expiry Date"
                    name={"expiry_date"}
                    placeholder="Enter Expiry Date here..."
                    type="month"
                    value={expiryDate2 || ""}
                    onChange={(e) => {
                      setexpiryDate2(e.target.value);
                      setExpiry(e.target.value + "-01");
                    }}
                  // required={true}
                  // error={showErrors ? errors["product_name"] : {}}
                  />
                </div>
                <div className="flex-1">
                  <LabelledInput
                    className={"SmallInput"}
                    label="MFG. Date"
                    name={"barcode"}
                    placeholder="Enter Manufacturer date here..."
                    type="month"
                    value={manufacturingDate2 || ""}
                    onChange={(e) => onChangeHandler(e, "MANUFACTURER")}
                  // error={showErrors ? errors["product_name"] : {}}
                  // required={true}
                  />
                </div>
                <div className="flex-1" style={{ maxWidth: "130px" }}>
                  <LabelledInput
                    className={"SmallInput"}
                    label="Sale Rate"
                    name={"saleRate"}
                    placeholder="Enter Sale rate here..."
                    type="text"
                    value={saleRate || ""}
                    onChange={(e) => setSaleRate(e.target.value)}
                    required={true}
                  />
                </div>
                {CheckPermissionFunc('add_purchase') && <div className="flex-1">
                  <button
                    id="button1"
                    type="button"
                    className="btn-none btn-active-cms"
                    onClick={editModal ? editItem : addItem}
                    style={{ width: "100%", marginTop: "22px", }}
                  >
                    {editModal ? "Update (Enter" : "Add (Enter "}
                    <FaSignInAlt /> )
                  </button>
                </div>}
              </div>
            </div>
          )}

          {true && (
            <>
              <StockTable
                cartData={cartData}
                stockdropdownList={stockdropdownList}
                inventoryname={inventoryname}
                deleteItem={deleteItem}
                setEditModal={setEditModal}
                setEditIndex={setEditIndex}
                setValues={setValues}
              />
              <div className="flex-row place-item-center">
                <button
                  id="button2"
                  className="btn-none btn-active-cms"
                  onClick={handleSubmit}
                  type={"button"}
                  style={{ marginBottom: "10px" }}
                >
                  Add {cartData.length > 0 && cartData.length} to stock (Shift +
                  Enter)
                </button>
              </div>
            </>
          )}
        </form>
      </div>

      <Modal unset={true} onClose={() => setEditModal(false)}>
        <div className="cross" onClick={() => setEditModal(false)}>
          ✕
        </div>
        <form
          className="forms"
          onSubmit={handleSubmit}
          id="form"
          // autoComplete="new-password"
          autocomplete="off"
        >
          <div className="everything-delivery-container">
            <div className="table__wrapper__stock">
              <h3 className="Page_Heading">Add Stock</h3>
              <div className="topbar__add_stock">
                <div className="responsive__wrapper">
                  <select
                    className="added__by"
                    onChange={(e) => onChangeHandler(e, "ADDED_BY")}
                    value={addedBy}
                  // data-addedBy={}
                  >
                    <option>Added By</option>
                    {stockdropdownList &&
                      stockdropdownList.added_by &&
                      stockdropdownList.added_by.map((added_by, index) => (
                        <option value={added_by.id} key={index}>
                          {added_by.phone_number}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="responsive__wrapper">
                  <textarea
                    className="product_barcode"
                    type="text"
                    placeholder="Barcode"
                    onChange={(e) => onChangeHandler(e, "BARCODE")}
                    value={barcode}
                  />
                </div>

                <div className="responsive__wrapper searchWrapper">
                  <div className="">
                    <input
                      className="suggestion_input"
                      type="text"
                      placeholder={editPlaceHolder}
                      onClick={() => {
                        setEditPlaceHolder("Search Product");
                      }}
                      style={{ backgroundColor: "white" }}
                      onChange={handleSearchProduct}
                      // value={tempProduct? tempProduct: null}
                      onKeyDown={(e) => {
                        let key = e.keyCode || e.charCode;
                        if (key === 8) {
                          setTempProduct("");
                          setProduct("");
                          setPrices("");
                          setMarketPrices("");
                        }
                      }}
                    />

                    <Searchsuggestion
                      productSuggestions={productSuggestions}
                      handleSuggestion={handleSuggestion}
                      showsuggestions={showSuggestions}
                    />
                  </div>
                </div>

                <div className="responsive__wrapper">
                  <div className="individual__component__wrapper">
                    <select
                      onChange={(e) => onChangeHandler(e, "UNIT")}
                      value={unit}
                      style={{ fontWeight: "500" }}
                    >
                      {stockdropdownList &&
                        stockdropdownList.stock_unit &&
                        stockdropdownList.stock_unit?.map(
                          (stock_unit, index) => (
                            <option value={stock_unit.id} key={index}>
                              {stock_unit.name}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                </div>

                <div className="">
                  <input
                    className="priceperproduct"
                    type="text"
                    placeholder="price"
                    style={{
                      backgroundColor: "white",
                      paddingLeft: "15px",
                      width: "100%",
                    }}
                    onChange={(e) => onChangeHandler(e, "PRICE_PER_PRODUCT")}
                    value={pricePerProduct}
                    list={"edit-price_options"}
                  />

                  <datalist id="edit-price_options">
                    <option value={prices}>Our Price</option>
                    {marketPrices && (
                      <option value={marketPrices}>Market Price</option>
                    )}
                  </datalist>
                </div>

                <div className="responsive__wrapper priceperproduct__wrapper bottom-buttons">
                  <input
                    className="product_quantity"
                    type="number"
                    placeholder="Quantity"
                    onChange={(e) => onChangeHandler(e, "QUANTITY")}
                    value={quantity}
                  />
                </div>
                <div className="responsive__wrapper priceperproduct__wrapper bottom-buttons">
                  <input
                    className="product_quantity"
                    type="date"
                    placeholder="expiry date"
                    onChange={(e) => {
                      setExpiry(e.target.value);
                    }}
                    value={expiry}
                  />
                </div>
                <div className="responsive__wrapper priceperproduct__wrapper bottom-buttons">
                  <input
                    className="product_quantity"
                    type="text"
                    placeholder="Batch Number"
                    onChange={(e) => {
                      setBatchNumber(e.target.value);
                    }}
                    value={batch_number}
                  />
                </div>

                <div className="responsive__wrapper bottom-buttons">
                  <button type="button" className="add" onClick={editItem}>
                    Edit
                  </button>
                </div>
                <div className="counter-container"></div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>

  );
}
